function scrollToo ($e, duration, y) {
    y = y || 0;
    duration = duration || 300;
    var _top = $e.offset().top + y;
    $('html,body').animate({"scrollTop" : _top}, duration);
    return false;
}

function placeholder () {
    var fields = $('.forma__field .forma__field__itext, .forma__field textarea');

    fields.each(function() {
        if ($(this).val() != '' && $(this).val() != $(this).parent().find('label').text()) $(this).parent().find('label').hide();
    });

    fields.keyup(function () {
        if ($(this).val() != '' && $(this).val() != $(this).parent().find('label').text()) $(this).parent().find('label').hide();
        else $(this).parent().find('label').show();
    }).focusin(function () {
        $(this).parent().addClass('forma__inp_light');
    }).focusout(function () {
        $(this).parent().removeClass('forma__inp_light');
    });
}

/*********************************
********* + Menu mobile **********
*********************************/

function menuToggle () {
    var body = $('body');
    var menu = $('#mm');
    var toggleBut = $('.menuToggle');
    var plusBut = $('#mm .mm__parent');

    toggleBut.click(function () {
        if (menu.css('display') == 'block') {
            //menu.animate({'opacity': 0}, 300, function() { $(this).hide(); });
            menu.slideUp(300, 'linear');
            toggleBut.removeClass('active');
        }
        else {
            menu.slideDown(300, 'linear');
            menu.show().animate({'opacity': 1}, 300);
            toggleBut.addClass('active');
        }
    });

    plusBut.click(function () {
        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
            $(this).next('.subs').slideUp(300, 'linear');
        } else if (!$(this).next().is('.subs:animated')) {
            $(this).addClass('active');
            $(this).next('.subs').slideDown(300, 'linear');
        }
    });

    $(window).resize(function() {
        setTimeout(function() {
            if (window.innerWidth >= 769) {
                menu.show();
                menu.find('.active').each(function() {
                    $(this).removeClass('active');
                    $(this).next().css('display', 'block');
                })
            } else if (!toggleBut.hasClass('active')) menu.hide();
        }, 50);
    });

    $(document).on('click touchstart', function(event) {
		var $target = $(event.target);
        if (!$target.is(menu) && !menu.find($target).length && !$target.is(toggleBut) && !toggleBut.find($target).length && toggleBut.hasClass('active')) toggleBut.click();
    });
}

function scrollToForm () {
    var button = $('.head__right .button');
    var forma = $('.request').prev();

    button.on('click touchstart', function(event) {
        scrollToo(forma, 400, -30);
        event.preventDefault();
    })
}

function carousel () {
	$('.carouselProjects').each(function() {
        $(this).owlCarousel({
            loop:true,
            margin:20,
            responsiveClass:true,
            responsive:{
                0:{
                    items:1,
                    nav:true
                },
                480:{
                    items:2,
                    nav:true
                },
                600:{
                    items:3,
                    nav:true
                },
                768:{
                    items:4,
                    nav:true
                },
                1000:{
                    items:5,
                    nav:true
                }
            }
        });
    });
    
    var owl = $('.slider__box');
    owl.owlCarousel({
        items:1,
        loop:true,
        margin:0,
        autoplay:true,
        nav:true,
        autoplayTimeout:5000,
        autoplayHoverPause:true
    });
}

function accordion () {
    var container = $('.addMenu_accordion');
    var link = $('.addMenu__item a');

    link.click(function () {
        if ($(this).parent().hasClass('active')) {
            $(this).parent().removeClass('active').next('.addMenu__box').slideUp(300, 'linear');
        } else if (!$(this).parent().next().is('div:animated')) {
            container.find('.active').removeClass('active').next('.addMenu__box').slideUp(300, 'linear');
            $(this).parent().addClass('active').next('.addMenu__box').slideDown(300, 'linear');
        }
        event.preventDefault();
    });

    if (container.find('[data-active="1"]').length) container.find('[data-active="1"] a').click();
}

function sendMsg () {
    var popup = $('.fixPopupMsg');
    var msg = $('.forma__sendMsg');
    var closeBut = $('.fixPopupMsg__close');
    
    var timeout;
    if (msg.find('div').length) {
        var classMsg = msg.find('div').attr('class');
        var textMsg = msg.find('div').text();
        popup.prepend('<div class="'+classMsg+'">'+textMsg+'</div>');
        popup.addClass('vis');
        timeout = setTimeout(function() {
            popup.animate({opacity : 'hide'}, 300, function() {
                $(this).removeClass('vis');
            });
        }, 5000);
        //popup.prepend('div class="'+classMsg+'"></div>')
    }

    closeBut.click(function() {
        clearTimeout(timeout);
        popup.animate({opacity : 'hide'}, 300, function() {
            $(this).removeClass('vis');
        })
    });
}

/********************************
********** Form Valid ***********
********************************/

/********************************
********** Form Valid ***********
********************************/

var CheckForms = function (form, button, options) {
    this.form = form;
    this.button = form.find(button);

    this.options = $.extend({}, CheckForms.Defaults, options);

    if (this.options.radio) this.radioChange();

    this.checkEmpty();
    this.submitForm();
    this.removeError();
}

CheckForms.Defaults = {
    errorClass: 'errorField',
    emptyClass: 'emptyField',
    radio: 0,
}

CheckForms.prototype.checkEmpty = function () {
    this.form.find('.requiredField').each(function () {
        if ($(this).val() == '' || $(this).val() == $(this).parent().find('label').text() || ($(this).is('[type = checkbox') && !$(this).is('input:checked'))) $(this).addClass('emptyField');
    });

    this.checkAllElements();
}

CheckForms.prototype.checkCorrect = function () {
    var patternEmail = /^[-._a-z0-9]+@(?:[a-z0-9][-a-z0-9]+\.)+[a-z]{2,6}$/;
    var patternDate = /^(0[1-9]|1\d|2\d|3[01])\.(0[1-9]|1[0-2])\.(19|20)\d{2}$/;
    this.form.find('.requiredField').each(function () {
        var item = $(this);
        var itemValue = item.val();
        var mailChecked = item.data('validate') != 'email' ? true : patternEmail.test(itemValue);
        var dateChecked = item.data('validate') != 'date' ? true : patternDate.test(itemValue);

        /**
         * @TODO:
         * 1. Get field type
         * 2. Check field by correct method (checkInput, checkRadio, checkCheckbox, checkEmail, checkDate)
         * 
         * checkDate by data-validate="date" + data-validatePattern="yyyy.mm.dd". Example: yyyy.mm.dd || dd.mm.yyyy
         **/ 
        if (!itemValue || !mailChecked || !dateChecked || itemValue == item.parent().find('label').text() || (item.is('[type = checkbox') && !item.is('input:checked'))) {
            item.addClass('errorField');
        }
        //if (item.is('[type = checkbox') && item.is('input:checked')) item.addClass('errorField');
    });

    this.checkAllElements();
}

CheckForms.prototype.removeError = function () {
    var patternEmail = /^[-._a-z0-9]+@(?:[a-z0-9][-a-z0-9]+\.)+[a-z]{2,6}$/;
    var patternDate = /^(0[1-9]|1\d|2\d|3[01])\.(0[1-9]|1[0-2])\.(19|20)\d{2}$/;
    //return patternEmail.test(value);

    this.form.on('keyup blur change', '.requiredField', function () {
        var item = $(this);
        var itemValue = item.val();
        var mailChecked = item.data('validate') != 'email' ? true : patternEmail.test(itemValue);
        var dateChecked = item.data('validate') != 'date' ? true : patternDate.test(itemValue);

        if (itemValue && itemValue != item.parent().find('label').text() && mailChecked && dateChecked) {
            item.removeClass('errorField emptyField');
        } else {
            itemValue == item.parent().find('label').text() ? item.addClass('emptyField') : item.addClass('errorField');
            if (!dateChecked) item.addClass('errorField');
        }

        if (item.is('[type = checkbox')) {
            item.is('input:checked') ? item.removeClass('errorField emptyField') : item.addClass('errorField');
        }

        var form = item.parents('form');
        var submitButton = form.find('.button_submit');
        if (!form.find('.emptyField').length && !form.find('.errorField').length) {
            submitButton.removeClass('button_disabled');
        } else {
            submitButton.addClass('button_disabled');
        }
    });
}

CheckForms.prototype.checkEmail = function (value) {
    var patternEmail = /^[-._a-z0-9]+@(?:[a-z0-9][-a-z0-9]+\.)+[a-z]{2,6}$/;
    return patternEmail.test(value);
}

CheckForms.prototype.checkAllElements = function () {
    if (!this.form.find('.emptyField').length && !this.form.find('.errorField').length) {
        this.button.removeClass('button_disabled');
    } else {
        this.button.addClass('button_disabled');
    }
}

CheckForms.prototype.submitForm = function () {
    this.button.click(function () {
        //this.checkEmpty();
        this.checkCorrect();
        event.preventDefault();
        if (!this.form.find('.emptyField').length && !this.form.find('.errorField').length) {
            this.form.submit();
        }
    }.bind(this));
}

function execContactsForm() {
    var feedForm = new CheckForms($('#feedForm'), '.button_submit');
}


function popupFeedForm() {
    $('.popupFeedForm').magnificPopup({
		type: 'inline',
		preloader: false,
		focus: '#name',

		// When elemened is focused, some mobile browsers in some cases zoom in
		// It looks not nice, so we disable it:
		callbacks: {
			beforeOpen: function() {
				if($(window).width() < 700) {
					this.st.focus = false;
				} else {
					this.st.focus = '#name';
				}
			}
		}
    });
}

/********************************
******** END Form Valid *********
********************************/

function initMap() {
    var mapCenter = $('meta[name="contacts:map-center"]').attr('content');

    ymaps.ready(function () {
        var myMap = new ymaps.Map('map', {
            center: mapCenter.split(','),
            zoom: 16,
            controls: ['fullscreenControl', 'zoomControl', 'geolocationControl']
        });
        myMap.behaviors.disable(['scrollZoom', 'rightMouseButtonMagnifier', 'multiTouch']);

    	for (var key in places) {

    		placesExec = new ymaps.Placemark(
    		    places[key],
    		    {
    		        balloonContentHeader: '<div class="mcapt">' + places[key][2] + '</div>'
    		        // balloonContentBody: '<div class="mimage">' + places[key][3] + '</div>',
    		        // balloonContentFooter: '<div class="mbm">' + places[key][2] + '</div>'

    		    },
    		    {
                    preset: 'islands#dotIcon', 
                    iconColor: '#f28465'
    		    }
    		);
    		myMap.geoObjects.add(placesExec);
    	}
    });
}

function popupGal() {
    var options = {
        delegate: 'a',
        type: 'image',
        tLoading: 'Загружено #%curr%...',
        mainClass: 'mfp-img-mobile',
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            tCounter: '<span class="mfp-counter">%curr% из %total%</span>', // markup of counter
            preload: [0,1] // Will preload 0 - before current, and 1 after the current image
        },
        image: {
            tError: '<a href="%url%">Изображение #%curr%</a> не может быть загружено.',
            titleSrc: function(item) {
                return item.el.attr('title') + '<small></small>';
            }
        }
    };
    $('.popup-gallery').magnificPopup(options);
}

x.exe['option-default'] = ['menuToggle()', 'placeholder()'];
x.exe['option-index'] = ['sendMsg()', 'scrollToForm()', 'carousel()', 'popupGal()'];
x.exe['option-contacts'] = ['initMap()', 'execContactsForm()', 'popupFeedForm()'];
x.exe['option-content'] = ['accordion()', 'popupGal()']
